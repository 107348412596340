import React, { useCallback, useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import jwt from 'jsonwebtoken';

import biogen from './img/logo-biogen.png';
import logoWhatsep from './img/logo-whatsep.png';

const API_URI = `${process.env.REACT_APP_SSL_ENABLED === 'true' ? 'https' : 'http'}://${process.env.REACT_APP_API_URI}:${
  process.env.REACT_APP_SSL_ENABLED === 'true' ? process.env.REACT_APP_API_PORT_SSL : process.env.REACT_APP_API_PORT
}`;
const SECRET = process.env.REACT_APP_JWT_SECRET;

const Home = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(false);
  const history = useHistory();

  const submitPassword = useCallback(
    async (e) => {
      e.preventDefault();
      const form = e.target;
      const pwd = form.password.value;
      try {
        const res = await axios.post(`${API_URI}/passwords/activate`, { game: 'whatsep', value: pwd });
        const { data } = res;
        const { token } = data;
        if (!token) {
          throw new Error('Invalid token');
        }
        // Keep token in localStorage
        localStorage.setItem(`license-whatsep`, token);
        // If the password was successfully activated, go to the game
        setWrongPassword(false);
        setShowPopup(false);
        history.push(`/whatsep`);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setWrongPassword(true);
        }
        console.log(error.message);
      }
    },
    [setWrongPassword, setShowPopup, history]
  );

  useEffect(() => {
    const license = localStorage.getItem(`license-whatsep`);
    if (license) {
      try {
        const { exp } = jwt.decode(license, SECRET);
        const dateNow = new Date();
        if (exp < dateNow.getTime() / 1000) {
          localStorage.removeItem(`license-whatsep`);
        } else {
          history.push(`/whatsep`);
        }
      } catch (error) {
        console.log(error);
        localStorage.removeItem(`license-whatsep`);
      }
    }
  }, [history]);

  useEffect(() => {
    if (!showPopup) {
      setWrongPassword(false);
    }
  }, [showPopup, setWrongPassword]);

  return (
    <React.Fragment>
      <header>
        <img src={biogen} alt='Biogen' />
      </header>
      <div id='content'>
        <img className='logo-whatsep' src={logoWhatsep} alt='WhatSEP' />
        <button className='bt' onClick={() => setShowPopup(true)}>
          Commencer
        </button>
      </div>
      <div id='job-number'>
        2021/01-Biogen-82044
        <br />
        2020/12-Biogen-75961
        <br />
        2020/11 Biogen-82345
      </div>

      <Modal show={showPopup} onHide={() => setShowPopup(false)} aria-labelledby='contained-modal-title-vcenter' centered>
        <Form className={wrongPassword ? 'invalid' : ''} onSubmit={submitPassword}>
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>Connexion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId='password'>
              <Form.Label>Veuillez saisir le mot de passe qui vous a été fourni</Form.Label>
              <Form.Control type='password' required />
              <Form.Control.Feedback type='invalid'>Mot de passe erroné</Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button className='bt' type='submit'>
              Valider
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default Home;
