import React, { useCallback, useEffect, useRef, useState } from 'react';
import Unity, { UnityContent } from 'react-unity-webgl';
import { Spinner, Button } from 'react-bootstrap';
import { isMobile, isIOS, isSafari } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import jwt from 'jsonwebtoken';

import ipad from './img/ipad.svg';
import phone from './img/phone-call.png';

const PROTOCOL = process.env.REACT_APP_SSL_ENABLED === 'true' ? 'https' : 'http';
const GAME_SERVER_URI = process.env.REACT_APP_GAME_SERVER_URI;
const GAME_PORT = process.env.REACT_APP_SSL_ENABLED === 'true' ? process.env.REACT_APP_GAME_PORT_SSL : process.env.REACT_APP_GAME_PORT;
const SECRET = process.env.REACT_APP_JWT_SECRET;

const Game = () => {
  const [unityContent, setUnityContent] = useState(null);
  const [ready, setReady] = useState(false);
  const [width, setWidth] = useState('100%');
  const [height, setHeight] = useState(isMobile ? '100%' : '95%');
  const [isLoading, setIsLoading] = useState(true);
  const [progression, setProgression] = useState(0);
  const [videoPath, setVideoPath] = useState('');
  const [videoPlaying, setVideoPlaying] = useState(false);
  const { slug } = useParams();
  const container = useRef(null);
  const game = useRef(null);
  const loading = useRef(null);
  const video = useRef(null);

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem(`license-${slug}`);
    window.location.reload();
  }, [slug]);

  const updateDimensions = useCallback(() => {
    const w = container.current.parentNode.clientWidth;
    const h = container.current.parentNode.clientHeight;
    if (isMobile) {
      if (w / h > 525 / 700) {
        setHeight('100%');
        setWidth((h * 525) / 700);
      } else {
        setWidth('100%');
        setHeight((w * 700) / 525);
      }
    } else {
      setWidth((h * 0.95 * 525) / 700);
    }
  }, [container, setWidth, setHeight]);

  const updateLoading = useCallback(
    (p) => {
      if (!isLoading) {
        return;
      }
      setProgression(p);
      if (loading.current !== null && p >= 1) {
        loading.current.classList.add('fade-out');
        setTimeout(() => setIsLoading(false), 500);
      }
    },
    [loading, isLoading, setProgression, setIsLoading]
  );

  const playVideo = useCallback(() => {
    setVideoPlaying(true);
    video.current && video.current.play();
  }, [video]);

  const pauseVideo = useCallback(() => {
    video.current && video.current.pause();
    setVideoPlaying(false);
  }, [video, setVideoPlaying]);

  const startCall = useCallback(() => {
    unityContent.send('CallInProgress', 'DoLaunchCall');
  }, [unityContent]);

  useEffect(() => {
    setUnityContent(new UnityContent(`/game/build.json`, '/game/UnityLoader.js', { adjustOnWindowResize: true }));
  }, []);
  useEffect(() => {
    const makeReady = () => setReady(true);
    const onAppReloaded = () => setReady(false);
    if (unityContent) {
      unityContent.on('PlayerReady', makeReady);
      unityContent.on('AppReloaded', onAppReloaded);
      unityContent.on('PlayVideo', playVideo);
      unityContent.on('PauseVideo', pauseVideo);
      unityContent.on('LoadVideo', setVideoPath);
      unityContent.on('progress', updateLoading);
    }
  }, [unityContent, setReady, setProgression, updateLoading, setVideoPath, playVideo, pauseVideo]);

  useEffect(() => {
    const license = localStorage.getItem(`license-whatsep`);
    if (ready && license) {
      try {
        const credentials = jwt.decode(license, SECRET);
        const { exp, session, admin } = credentials;
        const dateNow = new Date();
        if (exp < dateNow.getTime() / 1000) {
          localStorage.removeItem(`license-whatsep`);
          window.location.reload();
        } else {
          // Join the room
          unityContent.send('NetworkUser', 'InitNetworkUser', JSON.stringify({ room: session, protocol: PROTOCOL, server: GAME_SERVER_URI, port: GAME_PORT, admin: !!admin }));
          if (isIOS || isSafari) {
            unityContent.send('CallInProgress', 'SetIsIOS');
          }
        }
      } catch (error) {
        console.log(error);
        localStorage.removeItem(`license-whatsep`);
        window.location.reload();
      }
    }
  }, [ready, unityContent]);

  useEffect(() => {
    if (container.current) {
      updateDimensions();
      window.addEventListener('resize', updateDimensions, false);
      return () => {
        window.removeEventListener('resize', updateDimensions, false);
      };
    }
  }, [container, setWidth, setHeight, updateDimensions]);

  const onVideoEnded = useCallback(() => {
    setVideoPath('');
    setVideoPlaying(false);
    unityContent.send('CallInProgress', 'VideoEndReached');
  }, [unityContent, setVideoPlaying]);

  // const onTouchEnd = useCallback(e => {
  //   console.log('kokokokokok')
  //   if (!!video.current && !!videoPath && videoPlaying) {
  //     console.log('play', video.current)
  //     // setTimeout(() => {
  //       video.current.play().then(() => console.log('ok')).catch(err => {
  //         console.log(err)
  //       })
  //     // }, 200);
  //   }
  // }, [video, videoPath, videoPlaying])

  useEffect(() => {
    if ((isIOS || isSafari) && video.current && unityContent) {
      video.current.onended = onVideoEnded;
    }
  }, [video, unityContent, onVideoEnded]);

  return (
    <div ref={container} className='position-relative' style={isMobile ? { ...styles.wrapperNoIpad, width, height, flex: height === '100%' ? 1 : 0 } : { ...styles.wrapper, width, height }}>
      <Button variant='secondary' className='ml-2' style={{ position: 'fixed', top: 10, right: 10 }} onClick={logout}>
        Déconnexion
      </Button>
      <div ref={game} style={isMobile ? styles.noPhone : styles.phone}>
        {unityContent && <Unity unityContent={unityContent} />}
        {(isIOS || isSafari) && (
          <>
            <video
              ref={video}
              playsInline
              style={{ ...styles.video, display: !!videoPath && videoPlaying ? 'block' : 'none' }}
              src={videoPath}
              autoPlay={videoPlaying}
              className='position-absolute w-100 h-100'
            />
            {!!videoPath && !videoPlaying && (
              <Button className='position-absolute bt' style={styles.bt} onClick={startCall}>
                <img src={phone} alt='' style={{ width: '80%', height: 'auto' }} />
              </Button>
            )}
          </>
        )}
        {isLoading && (
          <div ref={loading} className='d-flex flex-column justify-content-center align-items-center bg-dark text-white position-absolute left-0' style={styles.loading}>
            <Spinner animation='border' role='status'>
              <span className='sr-only'>Loading...</span>
            </Spinner>
            <p className='mt-3' style={{ fontSize: 30 }}>{`${(progression * 100).toFixed()} %`}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Game;
const styles = {
  wrapper: {
    backgroundImage: `url(${ipad})`,
    maxHeight: '100%',
    maxWidth: '100%',
    alignSelf: 'center',
    position: 'relative',
    padding: '7.4vh 5.5vh',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    boxSizing: 'border-box',
    margin: 0,
    flex: 0.95,
  },
  wrapperNoIpad: {
    position: 'relative',
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  phone: {
    width: 'calc(100% - var(--vh, 1vh) * 12.7)',
    height: 'calc(100% - var(--vh, 1vh) * 17.8)',
    position: 'absolute',
    overflow: 'hidden',
    bottom: 25,
    top: 'calc(var(--vh, 1vh) * 9)',
    left: 'calc(50% + var(--vh, 1vh) * 0.05)',
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
    transform: 'translateX(-50%)',
  },
  noPhone: {
    width: '100%',
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  loading: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  video: {
    pointerEvents: 'none',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 10,
    objectFit: 'cover',
  },
  bt: {
    borderRadius: '50%',
    border: 'none',
    outline: 'none',
    background: '#77B650',
    width: 80,
    height: 80,
    minWidth: 80,
    minHeight: 80,
    left: '50%',
    bottom: 70,
    transform: 'translateX(-50%)',
  },
};
