import React, { useCallback, useState, useEffect } from 'react';
import { Container, Button, Tabs, Tab, Table, Modal, Form } from 'react-bootstrap';
import useInterval from '@use-it/interval';
import { CSVLink } from 'react-csv';
import axios from 'axios';

import copy from './img/copy.svg';

const API_URI = `${process.env.REACT_APP_SSL_ENABLED === 'true' ? 'https' : 'http'}://${process.env.REACT_APP_API_URI}:${
  process.env.REACT_APP_SSL_ENABLED === 'true' ? process.env.REACT_APP_API_PORT_SSL : process.env.REACT_APP_API_PORT
}`;

const REFRESH_DELAY = 5000;

const copyToClipboard = (i) => {
  const el = document.createElement('textarea');
  const checkbox = document.getElementById(`checkbox-${i}`);
  el.value = checkbox.parentNode.nextSibling.querySelector('button').innerText;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const copyPwdToClipboard = (e) => {
  e.stopPropagation();
  copyToClipboard(e.currentTarget.dataset.id);
};

const formatTime = (mins) => {
  const hours = (mins / 60) | 0;
  const minutes = mins - hours * 60;
  return `0${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
};
const getTimeString = (date) => {
  const str = new Date(date).toLocaleTimeString();
  return str.substr(0, str.length - 3);
};
const getStatus = (value) => (value === 0 ? 'Non actif' : value === 1 ? 'Actif' : 'Expiré');

const Dashboard = () => {
  const [passwords, setPasswords] = useState([]);
  const [invitations, setInvitations] = useState(['']);
  const [addingPwd, setAddingPwd] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [sendingRequest, setSendingRequest] = useState(false);
  const [addingSession, setAddingSession] = useState(false);
  const [csv, setCsv] = useState(null);
  const [closingSession, setClosingSession] = useState('');
  const [editingPwd, setEditingPwd] = useState(-1);
  const [activeTab, setActiveTab] = useState('');
  const [sessions, setSessions] = useState([]);
  const [archivedSessions, setArchivedSessions] = useState([]);
  const [token, setToken] = useState(null);

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    window.location.reload();
  }, []);

  /** Checkboxes */
  const toggleCheckbox = useCallback(
    (i) => {
      const checkbox = document.getElementById(`checkbox-${i}`);
      checkbox.checked = !checkbox.checked;

      const allCheckboxes = Array.from(document.querySelectorAll('.pwd-checkbox'));
      const checkboxes = allCheckboxes.filter((c) => c.checked);
      if (checkboxes.length === 0) {
        setAllChecked(false);
        setCsv(null);
        return;
      }
      setAllChecked(allCheckboxes.length === checkboxes.length);
      const toExport = passwords.filter((_, i) => checkboxes.some((checkbox) => parseInt(checkbox.dataset.id) === i));
      const csvData = [
        ['Mot de passe', 'Statut', 'Durée de validité', 'Date d’activation', 'Heure d’activation', 'Heure d’expiration'],
        ...toExport.map((pwd) => [
          pwd.value,
          getStatus(pwd.status),
          pwd.expires > 0 ? formatTime(pwd.expires) : '-',
          pwd.date ? new Date(pwd.date).toLocaleDateString() : '-',
          pwd.date ? getTimeString(pwd.date) : '-',
          pwd.date ? (pwd.expires > 0 ? `${getTimeString(new Date(pwd.date).getTime() + pwd.expires * 60000)}` : '-') : '-',
        ]),
      ];
      setCsv(csvData);
    },
    [passwords, setCsv, setAllChecked]
  );

  const toggleAllCheckboxes = useCallback(() => {
    const checkboxes = Array.from(document.querySelectorAll('.pwd-checkbox'));
    if (allChecked) {
      checkboxes.forEach((c) => (c.checked = false));
      setAllChecked(false);
    } else {
      checkboxes.forEach((c) => (c.checked = true));
      setAllChecked(true);
    }
  }, [allChecked, setAllChecked]);

  /** Passwords */
  const generatePwd = useCallback(
    async (e) => {
      e.stopPropagation();
      e.preventDefault();
      const form = e.target;
      const nbPasswords = form.nb.value;
      const allowMultiple = form.allowMultiple.checked;
      const isAdmin = form.isAdmin ? form.isAdmin.checked : undefined;
      const expires = form.duration.value === -1 ? 0 : form.duration.value * 60;
      const invites = invitations.map((_, i) => form[`invite-${i}`].value).filter((email) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email));

      try {
        setSendingRequest(true);
        const res = await axios.put(
          `${API_URI}/passwords`,
          { passwords: nbPasswords, expires, session: activeTab, invitations: invites, admin: isAdmin, allowMultiple },
          { headers: { 'x-auth-token': token } }
        );
        setSessions(sessions.map((sess) => (sess.id === activeTab ? { ...sess, passwords: [...sess.passwords, ...res.data.docs.ops] } : sess)));
      } catch (error) {
        console.error(error);
      }
      setAddingPwd(false);
      setSendingRequest(false);
    },
    [activeTab, setSessions, sessions, setAddingPwd, token, invitations, setSendingRequest]
  );

  const editPwd = useCallback(
    async (e) => {
      e.stopPropagation();
      e.preventDefault();
      try {
        const form = e.target;
        const expires = form.duration.value === -1 ? 0 : form.duration.value * 60;
        const allowMultiple = form.allowMultiple.checked;
        const admin = form.isAdmin.checked;
        const invitation = form.invitation ? (form.invitation.value && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(form.invitation.value) ? form.invitation.value : null) : null;
        const status = parseInt(form.status.value);
        const password = passwords[editingPwd];
        setSendingRequest(true);
        const res = await axios.post(`${API_URI}/passwords/${password._id}`, { ...password, status, expires, date: null, invitation, admin, allowMultiple }, { headers: { 'x-auth-token': token } });
        setSessions(sessions.map((sess) => (sess.id === password.session ? { ...sess, passwords: sess.passwords.map((pwd) => (pwd._id === password._id ? res.data : pwd)) } : sess)));
      } catch (error) {
        console.error(error);
      }
      setSendingRequest(false);
      setEditingPwd(-1);
    },
    [editingPwd, setEditingPwd, passwords, sessions, setSessions, token, setSendingRequest]
  );

  const deletePwd = useCallback(
    async (i) => {
      setEditingPwd(-1);
      try {
        const id = passwords[i]._id;
        await axios.delete(`${API_URI}/passwords/${id}`, { headers: { 'x-auth-token': token } });

        // If the checkbox was checked, uncheck it
        if (document.getElementById(`checkbox-${i}`).checked && csv && csv.length <= 2) {
          setCsv(null);
        }

        setSessions(sessions.map((sess) => (sess.id === activeTab ? { ...sess, passwords: sess.passwords.filter((pwd) => pwd._id !== id) } : sess)));
      } catch (error) {
        console.error(error);
      }
    },
    [activeTab, sessions, passwords, setEditingPwd, setSessions, token, csv, setCsv]
  );

  /** Sessions */
  const createSession = useCallback(
    async (e) => {
      e.stopPropagation();
      e.preventDefault();
      const {
        name: { value: name },
        game: { value: game },
      } = e.target;

      try {
        // Create new session
        const res = await axios.put(`${API_URI}/sessions`, { game, name }, { headers: { 'x-auth-token': token } });
        const { session } = res.data;
        setSessions([...sessions, session]);
        setAddingSession(false);
      } catch (error) {
        console.log(error.response);
      }
    },
    [token, sessions, setSessions, setAddingSession]
  );

  const closeSession = useCallback(
    async (id) => {
      try {
        await axios.post(`${API_URI}/sessions/close/${id}`, {}, { headers: { 'x-auth-token': token } });
        const session = sessions.find((sess) => sess.id === id);
        session.active = false;
        const newSessions = sessions.filter((sess) => sess.id !== id);
        if (activeTab === id) {
          setActiveTab(newSessions.length > 0 ? newSessions[0].id : '');
        }
        setClosingSession('');
        setSessions(newSessions.filter((sess) => sess.id !== id));
        setArchivedSessions([...archivedSessions, session]);
      } catch (error) {
        console.log(error.response);
      }
    },
    [token, activeTab, setActiveTab, sessions, setSessions, archivedSessions, setArchivedSessions, setClosingSession]
  );

  const fetchSessions = useCallback(
    async (storedToken) => {
      try {
        const res = await axios.get(`${API_URI}/sessions`, { headers: { 'x-auth-token': storedToken } });
        const { data } = res;
        const { sessions } = data;
        setSessions(sessions.filter((sess) => sess.active === true));
      } catch (error) {
        setToken(null);
        console.log(error, error.response);
      }
    },
    [setToken, setSessions]
  );

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (!storedToken) {
      return window.location.reload();
    }
    setToken(storedToken);

    // Fetch the sessions
    fetchSessions(storedToken);
  }, [setToken, setPasswords, setSessions, fetchSessions]);

  /** Once the token was stored, check for updates periodically */
  useInterval(() => token && fetchSessions(token), REFRESH_DELAY);

  /** Update tab contents (passwords displayed) */
  useEffect(() => {
    if (activeTab === '' && sessions.length > 0) {
      setActiveTab(sessions[0].id);
      setPasswords(sessions[0].passwords);
    }
    if (activeTab) {
      setPasswords(sessions.find((sess) => sess.id === activeTab).passwords);
    }
  }, [activeTab, setPasswords, setActiveTab, sessions]);

  /** Reset CSV when changing tabs */
  useEffect(() => {
    setCsv(null);
  }, [activeTab, setCsv]);

  useEffect(() => {
    if (!addingPwd) {
      setInvitations(['']);
    }
  }, [addingPwd, setInvitations]);

  return (
    <Container style={{ paddingBottom: 50 }} className='admin h-100 text-dark'>
      <header className='d-flex justify-content-between align-content-center mb-3 pt-3'>
        <h1 className='text-dark'>WhatSEP</h1>
        <div>
          <Button onClick={() => setAddingSession(true)}>+ Créer une session</Button>
          <Button variant='secondary' className='ml-2' onClick={logout}>
            Déconnexion
          </Button>
        </div>
      </header>
      {sessions.length > 0 && (
        <Tabs defaultActiveKey={sessions[0].id} id='dashboard-tabs' activeKey={activeTab} onSelect={setActiveTab}>
          {sessions.map((sess) => (
            <Tab key={sess.id} eventKey={sess.id} title={sess.name} className='pt-3 pb-3'>
              {activeTab === sess.id && (
                <React.Fragment>
                  <h5>{sess.gameName}</h5>
                  <div className='d-flex justify-content-between mb-3'>
                    <span>
                      {activeTab && (
                        <Button variant='info' className='mr-2' onClick={() => setAddingPwd(true)}>
                          + Créer un mot de passe
                        </Button>
                      )}
                      {activeTab && csv !== null && (
                        <CSVLink className='btn btn-primary' filename={`Mots_de_passes-${sess.name}.csv`} data={csv}>
                          Exporter
                        </CSVLink>
                      )}
                    </span>
                    <span>
                      <Button variant='danger' onClick={() => setClosingSession(sess.id)}>
                        Clore la session
                      </Button>
                    </span>
                  </div>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>
                          <input checked={allChecked} onChange={toggleAllCheckboxes} type='checkbox' />
                        </th>
                        <th>Mot de passe</th>
                        <th>Statut</th>
                        <th>Durée de validité</th>
                        <th>Date d’activation</th>
                        <th>Heure d’activation</th>
                        <th>Heure d’expiration</th>
                        <th>Activations</th>
                      </tr>
                    </thead>
                    <tbody>
                      {passwords.length > 0 &&
                        passwords.map((pwd, i) => {
                          const date = pwd.date ? new Date(pwd.date) : null;
                          const expireDate = pwd.expires > 0 ? (!!date ? new Date(date.getTime() + pwd.expires * 60000) : null) : null;
                          return (
                            <tr className={pwd.admin ? 'bg-success' : ''} key={pwd.value} onClick={() => toggleCheckbox(i)}>
                              <td>
                                <input type='checkbox' className='pwd-checkbox' id={`checkbox-${i}`} data-id={i} style={{ pointerEvents: 'none' }} />
                              </td>
                              <td>
                                <Button
                                  variant='link'
                                  className='p-0'
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setEditingPwd(i);
                                  }}
                                >
                                  {pwd.value}
                                </Button>
                                <img style={{ width: 20, height: 20, cursor: 'pointer', marginLeft: 5, marginBottom: 5 }} data-id={i} src={copy} alt='' title={'Copier'} onClick={copyPwdToClipboard} />
                              </td>
                              <td>{getStatus(pwd.status)}</td>
                              <td>{pwd.expires > 0 ? formatTime(pwd.expires) : '-'}</td>
                              <td>{date ? `${new Date(date).toLocaleDateString()}` : '-'}</td>
                              <td>{date ? `${getTimeString(date)}` : '-'}</td>
                              <td>{!!date && !!expireDate ? `${getTimeString(expireDate)}` : '-'}</td>
                              <td>{pwd.activations || (pwd.status !== 0 ? 1 : 0)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </React.Fragment>
              )}
            </Tab>
          ))}
        </Tabs>
      )}

      <Modal show={addingPwd} onHide={() => setAddingPwd(false)} aria-labelledby='contained-modal-title-vcenter' centered>
        <Form onSubmit={generatePwd}>
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>Nouveau mot de passe</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId='nb'>
              <Form.Label>Nombre d'invitations</Form.Label>
              <Form.Control
                type='number'
                min={1}
                defaultValue={1}
                onChange={(e) => setInvitations(e.target.value >= invitations.length ? [...invitations, ''] : invitations.filter((_, i) => i < invitations.length - 1))}
              />
            </Form.Group>

            <Form.Group controlId='duration'>
              <Form.Label>Durée de validité</Form.Label>
              <Form.Control as='select'>
                <option value={0.5}>00:30</option>
                <option value={1}>01:00</option>
                <option value={1.5}>01:30</option>
                <option value={2}>02:00</option>
                <option value={2.5}>02:30</option>
                <option value={3}>03:00</option>
                <option value={-1}>Illimitée</option>
              </Form.Control>
            </Form.Group>

            {invitations.length < 2 && (
              <Form.Group controlId='isAdmin'>
                <Form.Check type='checkbox' label='Admin' />
              </Form.Group>
            )}

            <Form.Group controlId='allowMultiple'>
              <Form.Check type='checkbox' label='Un seul mot de passe à usage multiple' />
            </Form.Group>

            <div>
              <span>Invitation(s) e-mail (Facultatif)</span>
              <br />
              <span style={{ fontSize: 12 }}>Veuillez entrer uniquement des adresses e-mail valides</span>
              <div style={{ maxHeight: 250, overflow: 'auto' }}>
                {invitations.map((_, i) => (
                  <Form.Group key={`invite-${i}`} controlId={`invite-${i}`}>
                    <Form.Control type='email' />
                  </Form.Group>
                ))}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type='submit'>Générer</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={editingPwd >= 0} onHide={() => setEditingPwd(-1)} aria-labelledby='contained-modal-title-vcenter' centered>
        {editingPwd >= 0 && (
          <Form onSubmit={editPwd}>
            <Modal.Header closeButton>
              <Modal.Title id='contained-modal-title-vcenter'>Editing</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId='status'>
                <Form.Label>Statut</Form.Label>
                <Form.Control as='select' defaultValue={passwords[editingPwd].status}>
                  <option value={0}>Non actif</option>
                  <option value={1}>Actif</option>
                  <option value={2}>Expiré</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId='duration'>
                <Form.Label>Durée de validité</Form.Label>
                <Form.Control as='select' defaultValue={passwords[editingPwd].expires > 0 ? passwords[editingPwd].expires / 60 : -1}>
                  <option value={0.5}>00:30</option>
                  <option value={1}>01:00</option>
                  <option value={1.5}>01:30</option>
                  <option value={2}>02:00</option>
                  <option value={2.5}>02:30</option>
                  <option value={3}>03:00</option>
                  <option value={-1}>Illimitée</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId='isAdmin'>
                <Form.Check type='checkbox' label='Admin' defaultChecked={passwords[editingPwd].admin} />
              </Form.Group>

              <Form.Group controlId='allowMultiple'>
                <Form.Check type='checkbox' defaultChecked={passwords[editingPwd].allowMultiple} label='Un seul mot de passe à usage multiple' />
              </Form.Group>

              {passwords[editingPwd].status === 0 && (
                <Form.Group controlId='invitation'>
                  <Form.Label>Invitation</Form.Label>
                  {passwords[editingPwd].invitationSent ? <p style={{ fontSize: 14, fontStyle: 'italic' }}>Ce mot de passe a déjà été envoyé à un utilisateur</p> : <Form.Control type='email' />}
                </Form.Group>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => deletePwd(editingPwd)} variant='secondary'>
                Supprimer le mot de passe
              </Button>
              <Button disabled={sendingRequest} type='submit'>
                Enregistrer
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Modal>

      <Modal show={addingSession} onHide={() => setAddingSession(false)} aria-labelledby='contained-modal-title-vcenter' centered>
        <Form onSubmit={createSession}>
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>Nouvelle session</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId='name'>
              <Form.Label>Nom de la session (Facultatif)</Form.Label>
              <Form.Control type='text' />
            </Form.Group>

            <Form.Group controlId='game'>
              <Form.Label>Application</Form.Label>
              <Form.Control as='select'>
                <option value='whatsep'>WhatSEP</option>
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type='submit'>Créer la session</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={closingSession !== ''} onHide={() => setClosingSession('')} aria-labelledby='contained-modal-title-vcenter' centered>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            closeSession(closingSession);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>Clore la session</Modal.Title>
          </Modal.Header>
          <Modal.Body>{closingSession !== '' && `Voulez-vous vraiment clore la session "${sessions.find((sess) => sess.id === closingSession).name}" ?`}</Modal.Body>
          <Modal.Footer>
            <Button variant='danger' type='submit'>
              Clore la session
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default Dashboard;
