import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import jwt from 'jsonwebtoken';

import Dashboard from './Dashboard';
import logoWhatsep from './img/logo-whatsep.png';

const API_URI = `${process.env.REACT_APP_SSL_ENABLED === 'true' ? 'https' : 'http'}://${process.env.REACT_APP_API_URI}:${
  process.env.REACT_APP_SSL_ENABLED === 'true' ? process.env.REACT_APP_API_PORT_SSL : process.env.REACT_APP_API_PORT
}`;
const SECRET = process.env.REACT_APP_JWT_SECRET;

const Admin = () => {
  const [logged, setLogged] = useState(false);
  const form = useRef(null);

  const tryLogin = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      const { login: username, password } = e.target;
      if (username.value.match(/^\s*$/) || password.value.match(/^\s*$/)) {
        return;
      }

      try {
        const res = await axios.post(`${API_URI}/auth`, { username: username.value, password: password.value });
        const { data } = res;
        const { token } = data;
        localStorage.setItem('token', token);
        form.current.classList.remove('invalid');
        setLogged(true);
      } catch (err) {
        console.log(err);
        if (err.response && err.response.status === 401) {
          // Wrong credentials
          form.current.classList.add('invalid');
        }
      }
    },
    [form, setLogged]
  );

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const { exp } = jwt.decode(token, SECRET);
        const dateNow = new Date();
        if (exp < dateNow.getTime() / 1000) {
          localStorage.removeItem('token');
          setLogged(false);
        } else {
          setLogged(true);
        }
      } catch (error) {
        localStorage.removeItem('token');
        setLogged(false);
        console.log(error.message);
      }
    } else {
      setLogged(false);
    }
  }, [logged, setLogged]);

  return logged ? (
    <Dashboard />
  ) : (
    <Container className='h-100 d-flex flex-column justify-content-around align-items-center'>
      <img className='logo-whatsep' src={logoWhatsep} style={{ height: 300, width: 'auto' }} alt='WhatSEP' />
      {/* <h1>Rétine Interactive</h1> */}
      <Form ref={form} onSubmit={tryLogin} className='w-100'>
        <Form.Group controlId='login'>
          <Form.Label>Login</Form.Label>
          <Form.Control type='text' placeholder='Login' required />
        </Form.Group>
        <Form.Group controlId='password'>
          <Form.Label>Mot de passe</Form.Label>
          <Form.Control type='password' placeholder='Mot de passe' required />
          <Form.Control.Feedback type='invalid'>Veuillez entrer une combinaison login/mot de passe valide</Form.Control.Feedback>
        </Form.Group>
        <Button className='bt' type='submit'>
          Connexion
        </Button>
      </Form>
    </Container>
  );
};

export default Admin;
