import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import './App.css';
import PrivateRoute from './PrivateRoute';
import Game from './Game';
import Home from './Home';
import Admin from './Admin';

const App = () => {
  return (
    <div className='App'>
      <div className='position-relative flex-grow-1 d-flex flex-column justify-content-center'>
        <Router>
          <Switch>
            <Route exact path='/admin' component={Admin} />
            <PrivateRoute exact path='/:slug' component={Game} />
            <Route exact path='/' component={Home} />
            <Redirect from='*' to='/' />
          </Switch>
        </Router>
      </div>
      <footer>
        <div>
          BIOGEN FRANCE SAS, Société par Actions Simplifiée au capital de 40000 Euros, N°398410126 RCS de Nanterre
          <br />
          Tour CBX - 1, Passerelle des Reflets 92913 Paris La Défense Cedex - France
        </div>
        <div className='text-right'>
          <a href='https://www.biogen-france.fr/fr_FR/privacy-policy.html' target='_blank' rel='noreferrer'>
            Politique de confidentialité
          </a>
          <br />
          <a href='https://www.biogen-france.fr/fr_FR/terms-conditions.html' target='_blank' rel='noreferrer'>
            Conditions d'utilisations
          </a>
        </div>
      </footer>
    </div>
  );
};

export default App;
